import * as React from "react";
import AssetPage from "../../components/asset-page";

const OptimismPage = () => (
  <AssetPage
    asset="Fantom"
    ticker="FTM"
    description={[
      `The blockchain sphere is teeming with innovative solutions, each aiming to address the trifecta of scalability, security, and decentralization. Among these rising stars, the Fantom Network stands out. Born from a vision to create a fast, secure, and scalable smart-contract platform, Fantom harnesses a unique consensus mechanism known as the Lachesis Protocol. This architecture underpins a network that delivers rapid transaction finality, all while facilitating a decentralized and agile environment for dApps and digital assets.`,
      `The Fantom Network is a beacon of innovation in the ever-evolving world of blockchain technology. With its groundbreaking Lachesis Protocol, commitment to eco-friendly scalability, and emphasis on interoperability, Fantom is carving out a significant niche in the decentralized landscape. As the need for efficient, interconnected, and sustainable blockchain solutions grows, Fantom's attributes position it at the forefront of the next generation of blockchain platforms.`,
    ]}
    hideFeatureImage
    features={[
      {
        title: "Lachesis Protocol",
        text: `The lifeblood of the Fantom Network, the Lachesis Protocol, is an aBFT (asynchronous Byzantine Fault Tolerant) consensus mechanism. This allows Fantom to process transactions with near-instant finality, addressing latency issues that plague many blockchain platforms. The efficiency of Lachesis ensures that users and developers experience swift and seamless interactions, making dApps on Fantom exceptionally responsive.`,
      },
      {
        title: "Eco-Friendly and Scalable",
        text: "One of the core challenges for many blockchains is achieving scalability without compromising energy efficiency. Fantom shines here, with its consensus mechanism designed to scale without the energy-intensive requirements of traditional Proof-of-Work (PoW) systems. This blend of scalability and eco-friendliness makes Fantom a sustainable choice for future-focused blockchain projects.",
      },
      {
        title: "Interoperability and Integration",
        text: "Fantom recognizes the importance of a connected, cohesive blockchain ecosystem. Its infrastructure facilitates easy integration with other blockchains and systems. This ensures that assets and data can flow smoothly across platforms, broadening the scope of dApps and services that can be built on and interact with the Fantom Network.",
      },
    ]}
  />
);

export default OptimismPage;
